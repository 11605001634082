import React, { Component, Fragment } from "react";
import ErrorMessage from "../ErrorMessage";
import ReactWhatsapp from "react-whatsapp";
import { Link } from "react-router-dom";
class AllProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products,
      phone: props.phone,
      uuid_users: props.uuid,
      title: props.title,
    };
  }
  render() {
    const { products, phone, errorMessage, title,uuid_users } = this.state;
    let content;
    if (errorMessage) {
      content = <ErrorMessage errorMessage={errorMessage} />;
    } else {
      content = (
        <div className="products-area">
          
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* section title */}
                <h2 className="section-title space-mb--20">{title}</h2>
                {/* featured products */}
                <div className="all-products-wrapper space-mb-m--20">
                  <div className="row">
                    {products &&
                      products.map((single) => {
                        return (
                          <div className="col-6" key={single.uuid}>
                            <Link
                              to={"/product/"+uuid_users+"/"+single.uuid}
                            >
                              <div className="grid-product__image">
                                <img
                                  src={process.env.PUBLIC_URL + single.image}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="grid-product__content">
                                <h3 className="title">{single.name}</h3>
                                <span className="category">
                                  {single.provider}
                                </span>
                              </div>
                            </Link>
                            <ReactWhatsapp
                              className="col-12 space-mb--20 button-info"
                              number={phone}
                              message={single.message}
                            >
                              Cotizar
                            </ReactWhatsapp>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <Fragment>{content}</Fragment>;
  }
}

export default AllProducts;
