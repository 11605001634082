import React, { Component, Fragment } from "react";

import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";

class CategorySlider extends Component {
  constructor(props) {
    super(props);
    this.state = { categoryData: props.categoryData, uuid: props.uuid };
  }
  render() {
    const { categoryData, errorMessage, uuid } = this.state;
    let content;
    const params = {
      spaceBetween: 15,
      breakpoints: {
        370: {
          slidesPerView: 5,
        },
        320: {
          slidesPerView: 3,
        },
      },
    };

    if (errorMessage) {
      content = <ErrorMessage errorMessage={errorMessage} />;
    } else {
      content = (
        <div className="category-slider-area bg-color--grey space-pb--25 space-mb--25">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* section title */}
                <h2 className="section-title space-mt--10 space-mb--20">
                  Mundos
                </h2>
                {/* category slider */}
                <div className="category-slider-wrapper">
                  <Swiper {...params}>
                    {categoryData &&
                      categoryData.map((single) => {
                        return (
                          <div key={single.uuid}>
                            <div className="category-item swiper-slide">
                              <div className="category-item__image">
                                <Link
                                  to={
                                    "/shop/" +
                                    uuid +
                                    "/" +
                                    single.uuid +
                                    "/" +
                                    single.title
                                  }
                                >
                                  <img
                                    src={process.env.PUBLIC_URL + single.image}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="category-item__title">
                                <Link
                                  to={
                                    "/shop/" + single.uuid + "/" + single.title
                                  }
                                >
                                  {single.title}
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <Fragment>{content}</Fragment>;
  }
}

export default CategorySlider;
